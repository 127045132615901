import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Img from 'gatsby-image';

const NotFoundPage = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "404.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <Layout>
      <SEO title="404: Not found" />
      <section className="section is-medium">
        <div className="container">
          <div className="columns is-vcentered">
            <div className="column has-text-centered">
              <h1 className="title">404 Page Not Found</h1>
              <p className="subtitle">
                Oops! Looks like you've gotten a bit lost.
              </p>
              <Link to="/" className="button is-primary">
                Take Me Home!
              </Link>
            </div>
            <div className="column has-text-centered">
              <Img fluid={data.placeholderImage.childImageSharp.fluid} />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default NotFoundPage;
